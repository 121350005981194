<template>
  <div class="unit-type-details">
    <div class="container fluid">
      <unit-type-detail
        type="developer"
        :canAdd="canManage"
        :canEdit="canManage"
        :canDelete="canManage"
      ></unit-type-detail>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    UnitTypeDetail: () =>
      import("@/modules/Project/components/UnitType/UnitTypeDetail")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      canManage: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAccessPermission();
    },
    async getAccessPermission() {
      this.canManage = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "manageProjects.modify"
      );
    }
  }
};
</script>

<style lang="scss"></style>
